import { API_URL, FAVOURITE } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import { Favourite } from '@/pages/customer/dashboard/[slug]/dashboard'

type FavouritesGetManyType = {
  type: FAVOURITE
}

export class FavouritesApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getMany(
    params?: FavouritesGetManyType,
    options?: RequestInit
  ): ApiHandlerResult<Favourite> {
    try {
      const res = await this.fetcher.get(API_URL.FAVOURITES.ROOT, {
        params,
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.favourites.getMany', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.favourites.getMany`, err)
      return [err, null]
    }
  }
}
