import { API_V1, API_V2 } from '@/constants'
import { AuthApi } from './fetchers/auth.api'
import { UserApi } from './fetchers/user.api'
import { Fetcher } from './fetcher'
import { ArticleApi } from './fetchers/articles.api'
import { TagsApi } from './fetchers/tags.api'
import { BusinessCategoryApi } from './fetchers/business-categories.api'
import { UiPlacementApi } from './fetchers/ui-placement'
import { FavouritesApi } from './fetchers/favourites.api'
import { SupplierApi } from './fetchers/suppliers.api'
import { ChatRoomApi } from './fetchers/chat-rooms.api'
import { RecyclesApi } from './fetchers/recycles.api'
import { AdminApi } from './fetchers/admin.api'
import { LocationsApi } from './fetchers/locations.api'
import { CustomerEventsApi } from './fetchers/customerEvent'
import { UploadsApi } from './fetchers/upload.api'
import { ApiErrorType } from '@/errors/apiError'
import { RevalidateApi } from './fetchers/revalidate.api'

export type ApiHandlerResult<T> = Promise<[ApiErrorType, null] | [null, T]>

class RoutesHandler {
  public admin: AdminApi
  public article: ArticleApi
  public auth: AuthApi
  public businessCategories: BusinessCategoryApi
  public chatRooms: ChatRoomApi
  public customerEvents: CustomerEventsApi
  public favourites: FavouritesApi
  public locations: LocationsApi
  public recycles: RecyclesApi
  public supplier: SupplierApi
  public tags: TagsApi
  public uiPlacement: UiPlacementApi
  public user: UserApi
  public uploads: UploadsApi
  public revalidate: RevalidateApi

  constructor(baseUrl: string) {
    const fetcher = new Fetcher(baseUrl)
    this.user = new UserApi(fetcher)
    this.auth = new AuthApi(fetcher)
    this.article = new ArticleApi(fetcher)
    this.tags = new TagsApi(fetcher)
    this.businessCategories = new BusinessCategoryApi(fetcher)
    this.uiPlacement = new UiPlacementApi(fetcher)
    this.favourites = new FavouritesApi(fetcher)
    this.supplier = new SupplierApi(fetcher)
    this.chatRooms = new ChatRoomApi(fetcher)
    this.recycles = new RecyclesApi(fetcher)
    this.admin = new AdminApi(fetcher)
    this.locations = new LocationsApi(fetcher)
    this.customerEvents = new CustomerEventsApi(fetcher)
    this.uploads = new UploadsApi(fetcher)
    this.revalidate = new RevalidateApi(fetcher)
  }
}

class Api {
  public fe: RoutesHandler
  public be: RoutesHandler
  public feTofe: RoutesHandler
  public feV2: RoutesHandler
  public beV2: RoutesHandler

  constructor() {
    this.fe = new RoutesHandler(API_V1)
    this.be = new RoutesHandler(process.env.NEXT_PUBLIC_API_URL!)
    this.feTofe = new RoutesHandler(`${process.env.NEXT_PUBLIC_BASE_URL}/api`)
    this.feV2 = new RoutesHandler(API_V2)
    this.beV2 = new RoutesHandler(`${process.env.NEXT_PUBLIC_API_URL}/v2`)
  }
}

export const api = new Api()
