export class ApiError extends Error {
  statusCode: number
  body: any
  constructor(statusCode: number, body: any) {
    super(`Api error: ${statusCode}`)
    this.statusCode = statusCode
    this.body = body
  }
}

export function isApiError(error: ApiErrorType | null, statusCode?: number) {
  if (!error) {
    return false
  }
  if ('statusCode' in error && statusCode === error.statusCode) {
    return true
  }

  return false
}

export type ApiErrorType = Error | ApiError
