import qs from 'qs'
import { ONE_HOUR_IN_SECONDS } from '@/constants'

export type FetcherOptions = RequestInit & {
  params?: unknown
}

export class Fetcher {
  public baseUrl: string
  protected defaultHeaders = {
    'Content-Type': 'application/json',
  }

  protected defaultNextOptions = {
    revalidate: ONE_HOUR_IN_SECONDS,
  }

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  protected buildUrl = (path: string, params?: unknown) => {
    const queryString = qs.stringify(params, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    })
    return this.baseUrl + path + queryString
  }

  protected requestHandlerFactory(method: string) {
    return async (path: string, init?: FetcherOptions) => {
      const url = this.buildUrl(path, init?.params)
      const { headers, ...restInit } = init || {}

      return fetch(url, {
        method,
        credentials: 'include',
        headers: { ...this.defaultHeaders, ...headers },
        next: this.defaultNextOptions,
        ...restInit,
      })
    }
  }

  public get = this.requestHandlerFactory('GET')
  public post = this.requestHandlerFactory('POST')
  public put = this.requestHandlerFactory('PUT')
  public delete = this.requestHandlerFactory('DELETE')
}
