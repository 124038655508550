import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'
import { PaginationResponse } from '@/types/api/pagination-response'
import { ApiHandlerResult } from '..'
import { API_URL } from '@/constants'
import { Chatroom, MessageSortKeys, MessageTypes } from '@/types/api/chat-room'

interface GetChatRoomsOptionsType {
  page?: number
  pageSize?: number
  messageType?: MessageTypes
  sortKey?: MessageSortKeys
  keyword?: string
  businessCategoryId?: string
  recycleId?: number
}

const DEFAULT_CHATROOMS_PAGE_SIZE = 9

export class ChatRoomApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async search(
    params?: GetChatRoomsOptionsType,
    options?: RequestInit
  ): ApiHandlerResult<PaginationResponse<Chatroom>> {
    try {
      const res = await this.fetcher.get(API_URL.CHATROOMS.ROOT, {
        params: {
          ...params,
          total: params?.pageSize,
        },
        ...options,
      })

      if (res.status !== 200) {
        const errBody = await res.json()
        console.log('>> error api.chatrooms.search', errBody)
        return [new Error(errBody), null]
      }

      const body = await res.json()

      // Until the endpoint is rewritten, the pagination is calculated here.
      const paginatedBody = {
        results: body.data as Chatroom[],
        pagination: {
          total: body.total as number,
          pageSize: (params?.pageSize as number) || DEFAULT_CHATROOMS_PAGE_SIZE,
          page: (params?.page as number) || 1,
        },
      }

      return [null, paginatedBody]
    } catch (err: any) {
      console.log(`>> error api.chatroom.search`, err)
      return [err, null]
    }
  }
}
