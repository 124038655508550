import { Fetcher } from '@/api/fetcher'
import { ApiError } from '@/errors/apiError'

export class BaseApi {
  protected fetcher: Fetcher

  constructor(fetcher: Fetcher) {
    this.fetcher = fetcher
  }

  protected async rethrowResponseError(res: Response) {
    if (res.status >= 400) {
      throw await { status: res.status, body: await res.json() }
    }
  }

  protected async parseBodyOrThrow(res: Response): Promise<[null, any]> {
    const body = await res.json()

    if (res.status >= 400) {
      throw new ApiError(res.status, body)
    }

    return [null, body]
  }
}
