import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { Fetcher } from '../fetcher'
import { Image } from '@/types/Image'

type ImageRequestParams = {
  files: { file: File; videoUrl?: string }[]
  targetId?: string
  dataType: 'article' | 'recycle' | 'supplier' | 'profile_picture' | 'logo'
}

export class UploadsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  /**
   * use on the frontend only
   */
  async uploadImagesToS3(
    payload: ImageRequestParams
  ): Promise<ApiHandlerResult<Image[]>> {
    const targetId = payload.targetId
    const dataType = payload.dataType

    const filesData = payload.files.map((fileObj) => ({
      fileName: fileObj.file.name,
      fileType: fileObj.file.type,
      videoUrl: fileObj.videoUrl,
    }))

    try {
      const response = await this.fetcher.post('/uploads/presigned-url', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ filesData, targetId, dataType }),
      })

      const presignedUrlsResponse = (await response.json()) as {
        presignedUrls: {
          fileName: string
          url: string
        }[]
        images: Image[]
      }

      const uploadPromises = presignedUrlsResponse.presignedUrls.map(
        ({ fileName, url }, index) => {
          const file = payload.files.find(
            (fileObj) => fileObj.file.name === fileName
          )

          if (!file) {
            console.log(`File ${fileName} not found`)
          }

          return fetch(url, {
            method: 'PUT',
            body: file?.file,
          }).then(async (uploadResponse) => {
            if (uploadResponse.ok) {
              console.log(`File ${file?.file.name} uploaded successfully`)
            } else {
              console.log(`File ${file?.file.name} upload failed`)
              console.log(await uploadResponse.text())
            }

            return uploadResponse
          })
        }
      )

      const uploadResults = await Promise.all(uploadPromises)

      if (uploadResults.some((result) => !result.ok)) {
        return [new Error('Upload failed'), null]
      }

      return [null, presignedUrlsResponse.images]
    } catch (error: any) {
      return [error, null]
    }
  }
}
