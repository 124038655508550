import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'
import { BusinessCategory } from '@/types/api/business-category'

export class BusinessCategoryApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getMany(): ApiHandlerResult<BusinessCategory[]> {
    try {
      const res = await this.fetcher.get(API_URL.BUSINESS_CATEGORIES)
      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
