import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'
import {
  UiPlacement,
  UiPlacementPlacement,
  UiPlacementResponse,
  UiPlacementType,
} from '@/types/api/ui-placement'

export type FetchUIPlacementParams = {
  placement: UiPlacementPlacement[] | UiPlacementPlacement
  type: UiPlacementType[] | UiPlacementType
  fresh?: boolean
}

export type PutUiPlacementPayload = UiPlacement[]

export class UiPlacementApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getMany(
    params: FetchUIPlacementParams,
    options?: RequestInit
  ): ApiHandlerResult<UiPlacementResponse> {
    try {
      const res = await this.fetcher.get(API_URL.UI_PLACEMENT.ROOT, {
        params,
        ...options,
      })

      await this.rethrowResponseError(res)
      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      return [err, null]
    }
  }

  async putUiPlacement(
    placement: string,
    payload: PutUiPlacementPayload,
    options?: RequestInit
  ): ApiHandlerResult<null> {
    try {
      const res = await this.fetcher.put(
        `${API_URL.UI_PLACEMENT.ROOT}/${placement}`,
        {
          body: JSON.stringify(payload),
          ...options,
        }
      )

      await this.rethrowResponseError(res)

      return [null, null]
    } catch (err: any) {
      return [err, null]
    }
  }
}
