import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { SignupDataType } from '@/components/layout/main'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'

type SignupOptionsType = {
  queryParams?: {
    redirectUrl?: string
  }
}

export class AuthApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async register(
    data: SignupDataType,
    options?: SignupOptionsType
  ): ApiHandlerResult<null> {
    try {
      const res = await this.fetcher.post(API_URL.AUTH.REGISTER, {
        body: JSON.stringify(data),
      })

      if (!res.ok) {
        const body = await res.json()
        return [new Error(body?.message), null]
      }

      return [null, null]
    } catch (err: any) {
      console.log('>> err', err)
      return [err, null]
    }
  }
}
