import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { Fetcher } from '@/api/fetcher'
import { ICurrentUser } from '@/utils/auth/auth'

export type VerifyEmailResponse = {
  isEmailAvailable: boolean
}

export class UserApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async verifyEmail(email: string): ApiHandlerResult<VerifyEmailResponse> {
    try {
      const res = await this.fetcher.post(API_URL.USERS.CHECK_EMAIL_EXIST, {
        body: JSON.stringify({ email }),
      })

      if (!res.ok) {
        throw new Error('Error validating email, try again later')
      }

      const resBody = await res.json()
      if (!resBody.isEmailAvailable) {
        throw new Error('This email already exists.')
      }

      return [null, resBody]
    } catch (error: any) {
      return [error, null]
    }
  }

  async getCurrent(options?: RequestInit): ApiHandlerResult<ICurrentUser> {
    try {
      const res = await this.fetcher.get(API_URL.USERS.CURRENT_USER, {
        ...options,
      })
      return await this.parseBodyOrThrow(res)
    } catch (err: any) {
      return [err, null]
    }
  }
}
