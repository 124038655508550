import { API_URL } from '@/constants'
import { ApiHandlerResult } from '..'
import { BaseApi } from '../base'
import { RequestInit } from 'next/dist/server/web/spec-extension/request'
import { Fetcher } from '@/api/fetcher'
import { ApiImage } from '@/types/Image'

/**
 * types here are only for this api endpoint purposes, don't use them in other places
 */
type Location = {
  id: number
  name: string
  abbreviation: string
  country_id: number
  region_id: number
  created_at: string
  updated_at: string
  image_id: number | null
  image: ApiImage | null
}

export type Country = {
  id: number
  name: string
  created_at: string
  updated_at: string
  location: Location[]
}

// @deprecated - use /regions
export class LocationsApi extends BaseApi {
  constructor(fetcher: Fetcher) {
    super(fetcher)
  }

  async getMany(options?: RequestInit): ApiHandlerResult<Country[]> {
    try {
      const res = await this.fetcher.get(API_URL.LOCATIONS.ROOT, {
        ...options,
      })

      await this.rethrowResponseError(res)
      const body = await res.json()
      return [null, body]
    } catch (err: any) {
      console.log(`>> error api.tags.getTags`, err)
      return [err, null]
    }
  }
}
